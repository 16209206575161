#Klanten{
	.logos {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: baseline;
		margin-bottom: 75px;
			img {
			height: 34px;
			margin: 15px;
			filter: opacity(50%) grayscale(100%);;
			}
				.logoKNMT {
				height: 40px;	
				}
				.logoDIAK {
				height: 28px;	
				}
				.logoReinier {
				height: 40px;	
				}
				.logoMSBD {
				height: 80px;	
				}
				.logoNVK {
				height: 200px;	
				}
				.logoVWS {
				height: 100px;	
				}
				.logoKNMG {
				height: 100px;	
				}
				.logoCRZ {
				height: 150px;	
				}
	}
}
@media (min-width: 576px) {
	#Klanten{
		.logos {
			img {
				height: inherit;
			}
		}
	}
}


