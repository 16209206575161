#Intro {

	text-align: center;
	display: flex;
	justify-content: center;
	background-image:  url(../../images/image-ziekenhuis.jpg);
	background-size: cover;
	background-position: center;
	color: #fff;
	margin-top: 120px;

 	.BlokOverlay {
		background-color: rgba(100, 100, 100, 0.8);
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		padding: 18px;
		display: flex;
		justify-content: center;
	}

	h1 {
		font-size: 30px;
	}

 	p {
	font-size: 18px;
	}

}

@media (min-width: 576px) {
	#Intro {
		.BlokOverlay {
			padding: 0px 45px 30px 45px;
		}
		h1 {
			font-size: 52px;
		}
		p {
		font-size: 21px;
		}
	}
}
