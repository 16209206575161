/* Blokken algemeen */
#Partners {
	text-align: center;
	display: flex;
	justify-content: center;

	h2 {
		color: #77bca8;
		font-size: 45px;
		margin: 0;
		padding: 45px 0;
	}

	.logos {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		margin-bottom: 75px;
		a:first-of-type {
			img {
				    border: 1px solid #aaa;
			}
		}
		img {
			width: 150px;
			margin: 15px;
			filter: opacity(50%) grayscale(100%);;
		}
	}

}

@media (min-width: 576px) {
	#Partners {
		.logos {
			img {
				width: inherit;
			}
		}
	}
}
