.Footer {
	min-height: 150px;
  background-color: #77bca8;
  text-align: center;
	display: flex;
	justify-content: center;
	color: #D6E8E2;
	font-weight: 600;


	.Container {
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		align-items: center;
		padding: 30px 0;
	}

	a {
		color: #D6E8E2;
		text-decoration: none;
	}

	div {
		text-align: center;
		.icon {
			width: 21px;
			height: 21px;
			vertical-align: bottom;
			polygon, path {
				fill: #D6E8E2;
			}
		}
		li {
			vertical-align: bottom;
		}
	}

	ul {
		list-style: none;
		margin: 0;
		padding: 0;
		li {
		}
	}

}

@media (min-width: 576px) {
	.Footer {
		.Container {
			flex-direction: row;
			align-items: flex-start;
		}
		div {
			text-align: left;
		}
	}
}
