/* Structuur */

.App {
  text-align: center;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-smoothing: antialiased;
}

.Container {
	max-width: 1024px;
	width: 100%;
	text-align: center;
}

/* Knoppen algemeen */
.btn {
	background-color: #E18230;
	border: 2px solid #E18230;
	height: 45px;
	color: #fff;
	border-radius: 6px;
	padding: 0 0 0 12px;
	font-size: 18px;
	font-weight: 700;
	display: flex;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
	box-shadow: 0px 3px 18px rgba(0, 0, 0, .4);
}

.btn .pijl {
	height: 30px;
	stroke: #fff;
	stroke-width: 4px;
}

.btn:hover {
	background-color: #fff;
	color:  #E18230;
}

.btn:hover .pijl {
	stroke:  #E18230;
}

/* Blokken algemeen */
.Blok {
	text-align: center;
	display: flex;
	justify-content: center;

	h2 {
		color: #77bca8;
		font-size: 30px;
		margin: 0;
		padding: 45px 0;
	}
}

@media (min-width: 576px) {
	.Blok {
		h2 {
			font-size: 45px;
		}
	}
}





