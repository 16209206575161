.Header {
  background-color: #77bca8;
  color: white;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 2;
  //display: flex;
  //justify-content: center;

  .Container {
    text-align: left;
    // display: flex;
    // flex-direction: column;
    // justify-content: space-between;
  }

  h1 {
    display: none;
    font-size: 21px;
    padding: 0;
    margin: 0;
  }

  a {
    align-self: center;
    cursor: pointer;
    color: #fff;
    text-decoration: none;
  }


  .Hamburger {
    position: absolute;
    width: 45px;
    width: 45px;
    top: 12px;
    right: 30px;
    cursor: pointer;
    transition: all 0.3s ease;
    polyline {
      stroke: #fff;
      stroke-width: 1.5px;
      fill: none;
      transition: transform 0.3s ease;
      transform-origin: 50% 50%;
    }
  }
  .Hamburger.open {
    .lijn1,
    .lijn4 {
      opacity: 0;
    }
    .lijn2 {
      transform: rotate(45deg);
    }
    .lijn3 {
      transform: rotate(-45deg);
    }
  }

  .Logo {
    height: 90px;
    margin: 12px 30px;
  }

  .Navigation {
    display: none;
  }
  .Navigation.open {
    display: block;
  }

  .Navigation {
    list-style-type: none;
    margin: 21px 0 0 0;
    padding: 0;
    text-align: center;
    li {
      list-style: none;
      display: block;
      min-width: 90px;
      font-weight: 700;

      a {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 45px;
        border-bottom: 2px solid #fff;
      }
    }
    li:hover {
      background: #fff;
      a {
        color: #77bca8;
      }
    }
  }
}

@media (min-width: 576px) {
  .Header {
    display: flex;
    justify-content: center;
    min-height: 120px;

    .hamburger {
      display: none;
    }

      .Logo {
        height: 90px;
        margin: 0px 30px;
      }

  .Navigation {
    display: block;
  }

    .Container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .Navigation {
      list-style-type: none;
      margin: 21px 0 0 0;
      padding: 0;
      li {
        list-style: none;
        display: inline-block;
        min-width: 90px;
        margin-right: 21px;
        font-weight: 700;
        a {
          border-bottom: 2px solid #77bca8;
        }
        .active {
          transition: border 0.3s linear;
          border-bottom: 2px solid #fff;
        }
      }
      li:hover {
        background: #77bca8;
        a {
          color: #fff;
          border-bottom: 2px solid #fff;
        }
      }
    }
  }
}
