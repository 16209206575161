.ShareButtons {
		background-color: #707070;
		width: 100%;
		height: 90px;
		display: flex;
		justify-content: center;
		align-items: center;
		.icon {
			width: 60px;
			height: 60px;
			path {
				fill: #86BAA9;
				stroke: #86BAA9;
			}
		}
		a:hover {
			.icon {
			path {
				fill: #fff;
				stroke: #fff;
			}
			}
		}

}

@media (min-width: 576px) {
	.ShareButtons {
	}
}
