/* Cards */
.Cards {
	display: flex;
	justify-content: center;
	color: #707070;
	font-weight: 600;
}

.Card {
	width: 450px;
	height: 95%;
	margin: 0 30px 45px 30px;
	padding: 30px;
	background-color: #fff;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;

	img {
		width: 100%;
		border-radius: 6px;
		flex-shrink: 0;
	}
	h4 {
		font-size: 28px;
		font-weight: 600;
		margin: 0 0 12px 0;
		padding: 0;
	}
	.Content {
		flex-grow: 1;
	}

	p {
		text-align: left;
		margin: 6px 0;
	}

	ul {
		text-align: left;
		margin-left: 18px;
		padding: 0;
	}
	.CardFooter {
		text-align: right;
	}
	a {
		display: inline-block;
		padding-top: 12px;
	}
}

@media (min-width: 576px) {
	.Cards {
		h3 {
			font-size: 36px;
		}
	}
}

@media (max-width: 768px) {
	.Cards {
		display: block;
	}

	.Card {
		width: calc(100% - 60px);
	}
}
