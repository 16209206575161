.SocialCard {
	width: 250px;
	height: 400px;
	margin: 0;
	padding: 30px;
	color: #707070;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;

	img {
		max-width: 210px;
		max-height: 210px;
		border-radius: 50%;
	}

	h4 {
		height: 21px;
		font-size: 21px;
		font-weight: 600;
		margin: 0;
		padding: 0;
	}

	p {
		width: 250px;
		height: 45px;
		font-size: 18px;
		font-weight: 400;
		margin: 0;
		padding: 0;
	}

	.SocialButtons {
		height: 45px;
		a {
			display: inline-block;
			background-color: #E18230;
			border: 2px solid #E18230;
			height: 36px;
			width: 36px;
			margin: 0 3px;
			color: #fff;
			border-radius: 650%;
			padding: 0;
			cursor: pointer;
			svg {
				width: 100%;
				height: 100%;
			}
		}
		a:hover {
			background-color: #fff;
			.icon {
				polygon, path {
					fill: #E18230;
				}
			}
		}
	}

}
