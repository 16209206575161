#OverOns {
	background-color: #F2F7F5;
	.SocialCards {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		color: #707070;
		font-weight: 600;
	}
}
