#Privacy {
	background-image:  url(../../images/image-pen.jpg);
	background-size: cover;
	background-position: center;
	position: relative;

	.Overlay {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		background-color: rgba(100, 100, 100, 0.6);
	}

	.Container {
		position: relative;
		min-height: calc(100vh - 240px);
		max-width: 600px;
		background-color: #fff;
		padding: 120px 45px 30px 45px;

		h4 {
			text-align: left;
			margin: 0;
			padding: 12px 0 0 0;
		}

		p {
			text-align: left;
			margin: 0 0 6px 0;
			padding: 0;
		}

	}

}
