#Contact {
	text-align: center;
	display: flex;
	justify-content: center;
	background-image: url(../../images/image-website-ordz.jpg);
	background-size: cover;
	background-position: center;

	.BlokOverlay {
		background-color: rgba(100, 100, 100, 0.6);
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		padding: 0px 45px 60px 45px;
		display: flex;
		justify-content: center;
	}

	h2 {
		color: #fff;
		font-size: 45px;
		margin: 0;
		padding: 45px 0;
	}

	.Form {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		.Fields {
			display: flex;
			flex-direction: column;
			justify-content: space-around;
			align-items: center;
			width: 100%;
			margin-bottom: 30px;

			.UserFields {
				height: 250px;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				width: 100%;
				color: #707070;

				input {
					display: block;
					height: 60px;
					font-size: 18px;
					padding: 12px;
					border-radius: 6px;
					border: 1px solid #fff;
				}
				input:last-of-type {
					margin-bottom: 0px;
				}

			}

			.InfoFields {
				min-height: 250px;
				background-color: #fff;
				width: 100%;
				border-radius: 6px;
				font-size: 18px;
				font-weight: 600;
				padding: 12px 30px;
				text-align: left;
				color: #707070;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				overflow: hidden;
				margin-top: 45px;

				label {
					display: flex;
					align-items: center;
				}

				input {
					margin-right: 21px;
				}

			}
		}

		.ReCAPTCHA {
			margin: 0px;
		}

		.btn {
			background-color: #E18230;
			border: 2px solid #E18230;
			width: 120px;
			height: 45px;
			color: #fff;
			border-radius: 6px;
			padding: 6px 12px;
			font-size: 18px;
			font-weight: 600;
			display: flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;
			box-shadow: 0px 3px 18px rgba(0, 0, 0, .5);
		}

		.btn:hover {
			background-color: rgba(0,0,0,0);
			//color:  #E18230;
		}

		.message {
			font-size: 21px;
			color: #fff;
			margin-top: 15px;
		}

	}
}



@media (min-width: 768px) {
	#Contact {
		.Form {
			.Fields {
    		flex-direction: row;
    		.UserFields {
    			width: 45%;
    			font-size: 21px;
    		}
    		.InfoFields {
    			margin-top: 0px;
    			width: 45%;
    			font-size: 21px;
    		}
			}
		}
	}
}



